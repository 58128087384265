import React, { useState } from "react";
import "./faqs.css";

const FaqsItems2 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleFaqClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const data = [
    {
      id: "01",
      question: "Маркетинг",
      answer: [
        {
          title: "ВЫЯВЛЕНИЕ НАРУШЕНИЙ",
          stage: [
            "— Определение ресурса и соотношение требований (кадры)",
            "— Фирменные цвета",
            "— Рекламные кампании",
            "— Анализ конкурентов",
            "— Цели, концепция, уникальность компании",
            "— Каталог, логотип, сайт, визитки, видеосъемка",
            "— Приемущества продукции",
            "— Портрет покупателя",
            "— Презентации по задачам",
            "— Бизнес планирование",
            "— Конференции и выставки",
            "— Нативная реклама",
            "— Определение сезонности товаров",
          ],
        },
        {
          title: "УСТРАНЕНИЯ НАРУШЕНИЙ",
          stage: [
            "— Дорожная карта по стандартизации процессов",
            "— Настройка процессов",
            "— Создание бренда",
            "— Обучение персонала",
            "— Фактическое управление по перестройке процесса",
            "— Разработка и внедрение ответственности и мотивации",
            "— Разработка и внедрение должностных инструкций",
            "— Еженедельный отчет о проделанной работе",
          ],
        },
        {
          title: "ГАРАНТИЯ",
          stage: [
            "— 	Разработка маркеров гарантийного обязательства",
            "— 	Возврат средств клиента в случае неэффективности",
            "— 	Премия в случае подтверждения эффективности",
          ],
        },
      ],
    },
    {
      id: "02",
      question: "Продажи",
      answer: [
        {
          title: "ВЫЯВЛЕНИЕ НАРУШЕНИЙ",
          stage: [
            "— Определение ресурса и соотношение требований (кадры)",
            "— Интеграция и настройка CRM системы",
            "— Система мотивации",
            "— Система контроля",
            "— Система обучения и развития менеджеров",
            "— Написание скриптов",
            "— Аналитика секретариата",
            "— Тайный покупатель",
          ],
        },
        {
          title: "УСТРАНЕНИЯ НАРУШЕНИЙ",
          stage: [
            "— Дорожная карта по стандартизации процессов",
            "— Настройка процессов",
            "— Обучение персонала",
            "— Фактическое управление по перестройке процесса",
            "— Разработка и внедрение ответственности и мотивации",
            "— Разработка и внедрение должностных инструкций",
            "— Еженедельный отчет о проделанной работе",
          ],
        },
        {
          title: "ГАРАНТИЯ",
          stage: [
            "— Разработка маркеров гарантийного обязательства",
            "— Возврат средств клиента в случае неэффективности",
            "— Премия в случае подтверждения эффективности",
          ],
        },
      ],
    },
    {
      id: "03",
      question: "Снабжение",
      answer: [
        {
          title: "ВЫЯВЛЕНИЕ НАРУШЕНИЙ",
          stage: [
            "— Определение ресурса и соотношение требований (кадры)",
            "— Анализ поставщиков товаров и материалов",
            "— Анализ поставщиков услуг",
            "— Расчет и оптимизация бюджета",
            "— Выявление 'откатов'",
            "— Анализ плана закупок",
            "— Анализ процедур",
            "— Анализ финансовой устойчивости (cash-flow)",
          ],
        },
        {
          title: "УСТРАНЕНИЯ НАРУШЕНИЙ",
          stage: [
            "— Дорожная карта по стандартизации процессов",
            "— Оптимизация закупок",
            "— Настройка процессов",
            "— Обучение персонала",
            "— Фактическое управление по перестройке процесса",
            "— Разработка и внедрение ответственности и мотивации",
            "— Разработка и внедрение должностных инструкций",
            "— Еженедельный отчет о проделанной работе",
          ],
        },
        {
          title: "ГАРАНТИЯ",
          stage: [
            "— Разработка маркеров гарантийного обязательства",
            "— Возврат средств клиента в случае неэффективности",
            "— Премия в случае подтверждения эффективности",
          ],
        },
      ],
    },
    {
      id: "04",
      question: "Кадры",
      answer: [
        {
          title: "ВЫЯВЛЕНИЕ НАРУШЕНИЙ",
          stage: [
            "— Определение ресурса и соотношение требований (кадры)",
            "— Штатное расписание",
            "— Дорожная карта",
            "— Бизнес процессы",
            "— Должностные инструкции",
            "— Система адаптации",
            "— Система найма",
            "— Нарушения Трудового Кодекса",
          ],
        },
        {
          title: "УСТРАНЕНИЯ НАРУШЕНИЙ",
          stage: [
            "— Дорожная карта по стандартизации процессов",
            "— Программы по найму и адаптации",
            "— Настройка процессов",
            "— Обучение персонала",
            "— Фактическое управление по перестройке процесса",
            "— Разработка и внедрение ответственности и мотивации",
            "— Разработка и внедрение должностных инструкций",
            "— Еженедельный отчет о проделанной работе",
          ],
        },
        {
          title: "ГАРАНТИЯ",
          stage: [
            "— Разработка маркеров гарантийного обязательства",
            "— Возврат средств клиента в случае неэффективности",
            "— Премия в случае подтверждения эффективности",
          ],
        },
      ],
    },
  ];

  return (
    <div className="faqs-items-container">
      {data.map((faq, index) => (
        <div
          className={`faqs-item ${index === activeIndex ? "active" : ""}`}
          key={index}
        >
          <div className="faqs-question" onClick={() => handleFaqClick(index)}>
            <div className="faqs-question-title">
              <ul>{faq.id}</ul>
              <h4>{faq.question}</h4>
            </div>
            <div className="faqs-button">
              <img src="./img/icons/arrow-top.svg" alt="arrow" />
            </div>
          </div>
          <div className="faqs-answer">
            <div className="faqs-includes-text">
              {faq.answer.map((el, i) => (
                <div key={i} className="faqs-includes-text-item">
                  <div className="faqs-includes-text-item-stage">
                    <img src="/img/icons/speedometer.svg" alt="" />
                    <h3>ЭТАП {i + 1}</h3>
                  </div>
                  <h3 className="faqs-includes-text-item-stage-title">
                    {el.title}
                  </h3>
                  <ul>
                    {el.stage.map((el, i) => (
                      <li key={i}>{el}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqsItems2;
