import React, { useState } from "react";
import "./faqs.css";

const FaqsItems1 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleFaqClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const data = [
    {
      id: "01",
      question: "Склад",
      answer: [
        {
          title: "ВЫЯВЛЕНИЕ НАРУШЕНИЙ",
          stage: [
            "— Определение ресурса и соотношение требований (кадры)",
            "— Приемка материалов на складе",
            "— Нарушения со стороны поставщика",
            "— Хранение материалов на складе",
            "— Выдача материалов на участки",
            "— Приемка готовой продукции",
            "— Отгрузка готовой продукции",
            "— Документооборот и точки контакта",
          ],
        },
        {
          title: "УСТРАНЕНИЯ НАРУШЕНИЙ",
          stage: [
            "— 	Дорожная карта по стандартизации процессов",
            "— 	Согласование этапов работ",
            "— 	Обучение персонала",
            "— 	Фактическое управление по перестройке процесса",
            "— Разработка и внедрение ответственности и мотивации",
            "— 	Разработка и внедрение должностных инструкций",
            "— 	Еженедельный отчет о проделанной работе",
          ],
        },
        {
          title: "ГАРАНТИЯ",
          stage: [
            "— 	Разработка маркеров гарантийного обязательства",
            "— 	Возврат средств клиента в случае неэффективности",
            "— 	Премия в случае подтверждения эффективности",
          ],
        },
      ],
    },
    {
      id: "02",
      question: "Цех",
      answer: [
        {
          title: "ВЫЯВЛЕНИЕ НАРУШЕНИЙ",
          stage: [
            "— Определение ресурса и соотношение требований (кадры)",
            "— Приемка материалов со склада",
            "— Качественные нормативы техногических процессов",
            "— Временные нормативы технологического процесса",
            "— Сдача полуфабриката ОТК",
            "— Перемещение полуфабриката",
            "— Приемка полуфабриката на участках",
            "— Сдача готовой продукции",
            "— Документооборот и точки контакта",
            "— Видеонаблюдение",
            "— Нормы и охрана труда",
          ],
        },
        {
          title: "УСТРАНЕНИЯ НАРУШЕНИЙ",
          stage: [
            "— Дорожная карта по стандартизации процессов",
            "— Согласование этапов работ",
            "— Обучение персонала",
            "— Фактическое управление по перестройке процесса",
            "— Разработка и внедрение ответственности и мотивации",
            "— Разработка и внедрение должностных инструкций",
            "— Еженедельный отчет о проделанной работе",
          ],
        },
        {
          title: "ГАРАНТИЯ",
          stage: [
            "— Разработка маркеров гарантийного обязательства",
            "— Возврат средств клиента в случае неэффективности",
            "— Премия в случае подтверждения эффективности",
          ],
        },
      ],
    },
    {
      id: "03",
      question: "Отдел технического контроля",
      answer: [
        {
          title: "ВЫЯВЛЕНИЕ НАРУШЕНИЙ",
          stage: [
            "— Определение ресурса и соотношение требований (кадры)",
            "— Соблюдение ГОСТ и ТУ готовой продукции",
            "— Соблюдение регламента приемки между участками",
            "— Соблюдение регламента приемки готовой продукции",
            "— Наличие тех карт, паспортов продукции, руководств и др. тех. документов",
            "— Документооборот и точки контакта",
          ],
        },
        {
          title: "УСТРАНЕНИЯ НАРУШЕНИЙ",
          stage: [
            "— Дорожная карта по стандартизации процессов",
            "— Согласование этапов работ",
            "— Обучение персонала",
            "— Фактическое управление по перестройке процесса",
            "— Разработка и внедрение ответственности и мотивации",
            "— Разработка и внедрение должностных инструкций",
            "— Еженедельный отчет о проделанной работе",
          ],
        },
        {
          title: "ГАРАНТИЯ",
          stage: [
            "— 	Разработка маркеров гарантийного обязательства",
            "— 	Возврат средств клиента в случае неэффективности",
            "— 	Премия в случае подтверждения эффективности",
          ],
        },
      ],
    },
  ];

  return (
    <div className="faqs-items-container">
      {data.map((faq, index) => (
        <div
          className={`faqs-item ${index === activeIndex ? "active" : ""}`}
          key={index}
        >
          <div className="faqs-question" onClick={() => handleFaqClick(index)}>
            <div className="faqs-question-title">
              <ul>{faq.id}</ul>
              <h4>{faq.question}</h4>
            </div>
            <div className="faqs-button">
              <img src="./img/icons/arrow-top.svg" alt="arrow" />
            </div>
          </div>
          <div className="faqs-answer">
            <div className="faqs-includes-text">
              {faq.answer.map((el, i) => (
                <div key={i} className="faqs-includes-text-item">
                  <div className="faqs-includes-text-item-stage">
                    <img src="/img/icons/speedometer.svg" alt="" />
                    <h3>ЭТАП {i + 1}</h3>
                  </div>
                  <h3 className="faqs-includes-text-item-stage-title">
                    {el.title}
                  </h3>
                  <ul>
                    {el.stage.map((el, i) => (
                      <li key={i}>{el}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqsItems1;
