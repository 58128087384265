import React, { useState } from "react";
import "./inputapplication.css";

const InputApplication = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    additionalMessage: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("ajax_applicationForm.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      //alert("Сообщение успешно отправлено");
    } catch (error) {
      console.error("Ошибка при отправке сообщения:", error);
      alert("Ошибка при отправке сообщения");
    }
  };

  return (
    <div>
      <div className="application-container" id="application">
        <img
          className="application-container-img"
          src="./img/collection/inputs-application.png"
          alt=""
        />

        <div className="application-inputs-items">
          <h3>Оставить заявку</h3>
          <form onSubmit={handleSubmit}>
            <div className="input-item">
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Имя"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Фамилия"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="input-item">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Номер телефона"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Почта"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <textarea
              className="additionalMessage"
              name="additionalMessage"
              placeholder="Дополнительное сообщение"
              value={formData.additionalMessage}
              onChange={handleChange}
            />
            <button className="submit-button" type="submit">
              Отправить <img src="./img/icons/button-submit.png" alt="" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InputApplication;
